<template>
  <page icon="home" :title="Naming.Overview" v-bind="{ loading }" loading-text="Crunching data">

    <div slot="tools">
      <new-job-dropdown v-if="hasAbility('Billow\\Models\\Job', 'create-jobs')"/>
    </div>

    <message v-if="dashboard.banner && dashboard.banner.active" :title="dashboard.banner.title" class="is-warning">
      <div class="is-flex align-center">
        <p class="mr-2">
          {{dashboard.banner.message}}
        </p>
      </div>
    </message>

    <message v-if="isOutOfTimeZone" title="Timezone Warning" class="is-warning" :closeable="true">
      <div class="is-flex align-center">
        <p class="mr-2">
          Your current timezone is not up-to-date with your profile, dates and times may not be accurate.
          <br>
          Your time zone <strong>{{ user.timezone | date('L LTS') }}</strong> differs to <strong>{{ derivedTimezone | date('L LTS') }}</strong>
        </p>
        <action-button :working="updatingTimezone" @click="realignTimezone" left-icon="clock">Change to
          {{ derivedTimezone | date('L LTS') }}
        </action-button>
      </div>
    </message>

    <message title="Authentication Security Alert" v-if="!mfaIsActive" class="is-danger">
      <p>
        Multi-factor Authentication is not currently set up. <router-link :to="{
          name: 'user-profile'
        }">Click here to secure your account.</router-link>
      </p>
    </message>

    <div class="columns">
      <div class="column">
        <div class="grid gap-1">
          <!-- Stats Row 1 -->
          <div class="grid gap-1 has-3-columns">
            <!-- Assets -->
            <route class="box summary-item-background" name="asset-admin-index">
              <summary-item
                  icon="coins"
                  horizontal
                  :title="Naming.Assets"
                  :value="$options.filters.abbreviateCount(counts.assets.total)"
                  :subvalue="`${counts.assets.today || 'none'} today`"/>
            </route>
            <!-- Inspections -->
            <route class="box summary-item-background" name="inspection-index">
              <summary-item
                  icon="arrow-right sm"
                  horizontal
                  :title="Naming.Inspections"
                  :value="$options.filters.abbreviateCount(counts.inspections.total)"
                  :subvalue="`${counts.inspections.today || 'none'} today`"/>
            </route>
            <!-- Open Jobs -->
            <route class="box summary-item-background" name="job-index" :query="{ status: 'open' }">
              <summary-item
                  icon="clock"
                  :title="`In-Progress ${Naming.Jobs}`"
                  :value="dashboard.total_open_jobs"/>
            </route>
          </div>
          <!-- row 2 -->
          <div class="grid gap-1 has-3-columns">
            <!-- Defective Assets -->
            <route class="box summary-item-background" name="asset-admin-index" :query="{ defective: 1 }">
              <summary-item
                  icon="exclamation-triangle"
                  icon-class="has-text-danger"
                  horizontal
                  :title="`defective ${Naming.Assets}`"
                  :value="counts.assets.defective"
                  :subvalue="`${Number(defectiveAssetPercentage).toFixed(0)}%`"/>
            </route>
            <!-- Overdue for Inspection -->
            <route class="box summary-item-background" name="overdue-inspection-index">
              <summary-item
                  icon="arrow-right sm"
                  horizontal
                  :title="`overdue ${Naming.Inspections}`"
                  :value="counts.inspections.overdue_inspections"/>
            </route>
            <!-- Requires Sign Off Jobs -->
            <route class="box summary-item-background" name="job-index" :query="{ status: 'pending-signature' }">
              <summary-item
                  :icon="loadingPendingSignatures ? 'spinner' : 'clock'"
                  :class="{'spinner-spin': loadingPendingSignatures}"
                  title="pending signatures"
                  :value="pendingSignatures"/>
            </route>
          </div>
          <!-- row 3 -->
          <div class="grid gap-1 has-3-columns">
            <!-- Clients -->
            <route class="box summary-item-background" name="client-admin-index">
              <summary-item
                  icon="address-book"
                  horizontal
                  :title="Naming.Clients"
                  :value="$options.filters.abbreviateCount(counts.clients.total)"/>
            </route>
            <!-- Sites -->
            <route class="box summary-item-background" name="site-admin-index">
              <summary-item
                  icon="building"
                  horizontal
                  :title="Naming.Sites"
                  :value="$options.filters.abbreviateCount(counts.sites.total)"/>
            </route>
            <!-- Technicians -->
            <route class="box summary-item-background" name="user-index" v-if="!hasRole('client')">
              <summary-item
                  icon="user"
                  horizontal
                  :title="Naming.Technicians"
                  :value="$options.filters.abbreviateCount(counts.technicians.total)"/>
            </route>
          </div>

          <!-- Charts etc -->
          <widget collapseable :title="Naming.Asset + ' &amp; ' + Naming.Inspection + ' Overview'">
            <div class="columns is-vcentered">
              <div class="column is-5" v-if="defectiveAssetPercentage">
                <asset-health
                    :data="assetRisk"
                    legendPosition="bottom"
                    height="300"/>
                <p class="has-text-centered has-text-weight-bold mt-1">
                  <route name="asset-admin-index" :query="{ defective: 1 }" class="has-text-info">Defective {{ Naming.Assets }}
                    &rarr;
                  </route>
                </p>
              </div>
              <div class="column" v-if="!hasRole('client')">
                <inspections-over-time/>
              </div>
            </div>
          </widget>
          <widget collapseable :title="`Top 10 ${Naming.Technicians} This Month`" v-if="hasRole('admin')">
            <top-technicians/>
          </widget>
          <widget collapseable :title="`Recent ${Naming.Inspections} Locations`" class="is-slot-clipped">
            <GmapMap
                :center="centerOfMap"
                :zoom="7"
                :options="googleMapOptions"
                map-type-id="terrain"
                style="width: 100%; height: 400px">
              <GmapMarker v-for="marker in markers" :key="marker.id"
                          :position="{ lat: marker.location.latitude, lng: marker.location.longitude }"/>
            </GmapMap>
          </widget>
        </div>
      </div>
      <div class="column is-3" v-if="dashboard.recent_inspections.length && (hasRole('admin') || hasRole('client'))">
        <recent-inspections :inspections="dashboard.recent_inspections"/>
      </div>
    </div>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import AssetHealth from '@/components/graph/AssetHealth'
import InspectionsOverTime from '@/components/graph/InspectionsOverTime'
import TopTechnicians from '@/components/graph/TopTechnicians'
import UpcomingJobs from '@/components/dashboards/admin/UpcomingJobs'
import RecentInspections from './partials/RecentInspections'

import defaultMapStyles from '@/theme/gmaps/default'

export default {

  components: {
    AssetHealth,
    InspectionsOverTime,
    UpcomingJobs,
    TopTechnicians,
    RecentInspections
  },

  data: () => ({
    loading: true,
    loadingPendingSignatures: true,
    centerOfMap: { lat: -29.756377840872975, lng: 31.026159912609653 },
    updatingTimezone: false,
    googleMapOptions: {
      styles: defaultMapStyles
    }
  }),

  async beforeMount() {
    await this.$store.dispatch('dashboard/loadAdmin')
    this.loading = false
  },

  async created() {
    await this.$store.dispatch('dashboard/loadPendingSignatures')
    this.loadingPendingSignatures = false
  },

  mounted() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.centerOfMap.lat = position.coords.latitude
      this.centerOfMap.lng = position.coords.longitude
    }, error => {})
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    realignTimezone() {
      this.updatingTimezone = true
      this.$store.dispatch('auth/realignTimezone', this.derivedTimezone).then(() => {
        this.updatingTimezone = false
      }).catch(error => {
        this.updatingTimezone = false
      })
    },
    hasRole(roleName) {
      return this.user.roles.filter(userRole => userRole.name === roleName).length > 0
    },
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    }
  },

  computed: {
    ...mapGetters('dashboard', [
      'dashboard',
      'pendingSignatures'
    ]),
    ...mapGetters('auth', [
      'user',
      'isOutOfTimeZone',
      'derivedTimezone',
      'mfaIsActive'
    ]),
    counts() {
      return this.dashboard.counts
    },
    assetRisk() {
      return {
        labels: Object.keys(this.counts.risk),
        series: Object.values(this.counts.risk),
      }
    },
    defectiveAssetPercentage() {
      return (this.counts.assets && this.counts.assets.defective > 0) ? ((this.counts.assets.defective / this.counts.assets.total) * 100).toFixed(2) : 0
    },
    markers() {
      return this.dashboard.recent_inspections.filter(inspection => inspection.location !== null && inspection.location.latitude !== null)
    }
  }

}
</script>
